import { ACCESS_TOKEN } from '../mutation-types'
import storage from 'store'
import { api } from '@/utils/api'
import Router from '@/router'
import { message } from 'ant-design-vue'
import { setCookie, setUrlParam } from '../../utils'

export const user = {
  state: {
    info: [],
    name: '',
    avatar: ''
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },

    SET_NAME: (state, name) => {
      state.name = name
    },

    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    }
  },

  actions: {
    logoutOA({ dispatch }) {},

    clearLoginCache({ commit }) {
      window.document.cookie = 'BBCloud-Access-Token=;domain=.babybus.com;path=/'
      window.document.cookie = 'BBCloud-Access-Token=;domain=localhost;path=/'
      storage.remove(`${ACCESS_TOKEN}`)
      commit('SET_NAME', '')
      commit('SET_AVATAR', '')
      commit('SET_INFO', [])
    },

    // 获取用户信息
    initUserInfo({ commit }) {
      return api.apiUserInfoGet().then(res => {
        commit('SET_INFO', res)
        commit('SET_NAME', res.name)
        commit('SET_AVATAR', res.avatar)
      })
    },

    passiveLogout({ dispatch }) {
      dispatch('clearLoginCache')
    },
    getAccessToken({ commit, dispatch }, payload) {
      if (payload.grant_type === 'wx') {
        return api
          .apiOauth2AuthByWechatPost({
            code: payload.code,
            client_id: payload.client_id
          })
          .then(res => {
            if (res?.token?.access_token) {
              setCookie(ACCESS_TOKEN, res.token.access_token, 7, '.babybus.com')
              if (window.location.hostname === 'localhost') {
                setCookie(ACCESS_TOKEN, res.token.access_token, 7)
              }
            }
            return res
          })
      }
      const hide = message.loading('登录中请稍后', 0)
      return api
        .apiOauth2TokenPost(payload)
        .then(res => {
          // const loading = message.loading('登录成功，跳转中...', 2)
          setCookie(ACCESS_TOKEN, res.access_token, 7, '.babybus.com')
          if (window.location.hostname === 'localhost') {
            setCookie(ACCESS_TOKEN, res.access_token, 7)
          }
          // loading()
        })
        .catch(error => {
          if (error.response.status === 302) {
            hide()
            message.loading('钉钉安全验证跳转中..', 2)
            setTimeout(() => {
              dispatch('goDingLogin', {
                client_id: payload.client_id,
                redirect_uri: payload.redirect_uri,
                state: payload.state,
                scope: payload.scope,
                DING_APP_KEY: payload.DING_APP_KEY
              })
            }, 1500)
          }
          throw error
        })
        .finally(() => {
          hide()
        })
    },
    getAccessCode({ commit }, payload) {
      if (payload.client_id && payload.redirect_uri) {
        // message.loading('授权跳转中...', 2)
        return api.apiOauth2CodeGet(payload.client_id, payload.redirect_uri).then(res => {
          let href = setUrlParam(payload.redirect_uri, 'code', res.access_code)
          if (payload.state) {
            href = setUrlParam(href, 'state', payload.state)
          }
          window.location.href = href
        })
      } else {
        Router.push({ name: 'UserCenter' })
      }
    },

    redirectQuery({ commit }, payload) {
      let uri = payload.redirect_uri
      for (const key in payload.request) {
        uri = setUrlParam(uri, key, payload.request[key])
      }
      window.location.href = uri
    },
    redirectFormPost({ commit }, payload) {
      const form = document.createElement('form')
      form.action = payload.redirect_uri
      form.method = 'POST'
      form.style.display = 'none'
      for (const key in payload.request) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = key
        input.value = payload.request[key]
        form.appendChild(input)
      }
      document.body.appendChild(form)
      form.submit()
    },
    redirectProcess({ commit, dispatch }, payload) {
      if (payload.response_mode === 'form_post') {
        dispatch('redirectFormPost', payload)
      } else {
        dispatch('redirectQuery', payload)
      }
    },
    redirectHandle({ commit, dispatch }, payload) {
      if (payload.client_id && payload.redirect_uri) {
        if (payload.response_type === 'id_token') {
          return api
            .apiOauth2IdTokenGet(payload.client_id, payload.redirect_uri, payload.scope, payload.state, payload.nonce)
            .then(res => {
              dispatch('redirectProcess', {
                redirect_uri: payload.redirect_uri,
                response_mode: payload.response_mode,
                request: {
                  id_token: res.id_token,
                  state: payload.state
                }
              })
            })
        } else {
          return api.apiOauth2CodeGet(payload.client_id, payload.redirect_uri).then(res => {
            dispatch('redirectProcess', {
              redirect_uri: payload.redirect_uri,
              response_mode: payload.response_mode,
              request: {
                code: res.access_code,
                state: payload.state
              }
            })
          })
        }
      } else {
        Router.push({ name: 'UserCenter' })
      }
    },
    getUserInfo({ commit }) {
      return api.apiUserInfoGet().then(res => {
        commit('SET_INFO', res)
        commit('SET_NAME', res.name)
        commit('SET_AVATAR', res.avatar)
      })
    },
    goDingLogin({ commit }, payload) {
      const { origin } = window.location
      const REDIRECT_URI = encodeURIComponent(origin + '/dingtalk_login')
      //base64编码 client_id=${client_id}&redirect_uri=${redirect_uri}&originState=${state}&scope=${scope}
      const dingState = btoa(
        JSON.stringify({
          client_id: payload.client_id,
          redirect_uri: payload.redirect_uri,
          state: payload.state,
          scope: payload.scope
        })
      )
      window.location.href = `https://login.dingtalk.com/oauth2/auth?redirect_uri=${REDIRECT_URI}&response_type=code&client_id=${payload.DING_APP_KEY}&scope=openid&prompt=consent&state=${dingState}`
    }
  }
}
